// Core
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// Components
import { NotFound as NotFoundComponent } from "components/common/Errors";

// Definitions
import type { GetStaticPropsContext } from "next";

// Utils
import { verifyEnvironment } from "utils/verify-environment";
import { logger } from "utils/logger";
import { getLocaleFromCtx } from "utils/locale-from-context";

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const { isDevelopment } = verifyEnvironment();
  const localeGroups = ["cookies", "common", "buttons", "inputs"];

  try {
    const locale = getLocaleFromCtx(ctx);
    const i18PageConfig = await serverSideTranslations(locale, localeGroups);
    return {
      props: {
        ...i18PageConfig,
      },
    };
  } catch (error) {
    const err = error as Error;
    const i18PageConfigDefault = await serverSideTranslations("en", ["common"]);
    if (isDevelopment) {
      logger.serverDevelopmentLogger.error(
        `GetServerSideProps {"page": "/404", producer: "SSR Server" } has crushed with message: ${err.message}`,
      );
    }
    return {
      props: {
        ...i18PageConfigDefault,
      },
    };
  }
};

const Custom404Page = () => {
  return <NotFoundComponent />;
};

export default Custom404Page;
